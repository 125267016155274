import React, { useEffect, useState } from 'react';
import './NavbarToggler.css';
import { HiBars3 } from 'react-icons/hi2';
import classnames from 'classnames';
import { addAlphaToHex } from '../../utils';

export default function NavbarToggler({ onClick, className }) {
	const [color, setColor] = useState();
	useEffect(() => {
		const iconsColor = getComputedStyle(document.documentElement)
			.getPropertyValue('--IconsColor')
			.replace('#', '');
		setColor(addAlphaToHex(iconsColor, 0.3));
	}, []);
	return <div
		style={{ borderColor: color }}
		className={classnames('nav-toggler', { [className]: className })} >
		<button onClick={onClick}>
			<HiBars3 />
		</button>
	</div>;
};