import React, { useContext, useState, useLayoutEffect } from 'react';
import colorService from '../../services/color.service';
import './ColorConfig.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import { useNavigate } from 'react-router-dom';
import { getStatus } from '../../utils';

// const reducer = (state, action) => {
// 	switch (action.type) {
// 	case 'FETCH_REQUEST':
// 		return { ...state, loading: true };
// 	case 'FETCH_SUCCESS':
// 		return { ...state, colors: action.payload, loading: false };
// 	case 'FETCH_FAIL':
// 		return { ...state, loading: false, error: action.payload };
// 	default:
// 		return state;
// 	}
// };

const ColorConfig = () => {
	// const [state, dispatch] = useReducer(reducer, {
	// 	colors: {},
	// 	loading: true,
	// 	error: ''
	// });

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const [primaryColor, setPrimaryColor] = useState();
	const [shadowNavColor, setShadowNavColor] = useState();
	const [secondaryColor, setSecondaryColor] = useState();
	const [backgroundColor, setBackgroundColor] = useState();
	const [utilsColor, setUtilsColor] = useState();
	const [navFontColor, setNavFontColor] = useState();
	const [iconsColor, setIconsColor] = useState();
	const [titlesColor, setTitlesColor] = useState();
	const [btnFontColor, setBtnFontColor] = useState();
	const navigate = useNavigate();

	//getting theme colors-to do: load before page

	useLayoutEffect(() => {
		const fetchData = async () => {
			try {
				ctxDispatch({ type: 'FETCH_REQUEST' });
				const data = await colorService.getColors();
				setPrimaryColor(data.primaryColor);
				setShadowNavColor(data.shadowNavColor);
				setSecondaryColor(data.secondaryColor);
				setBackgroundColor(data.backgroundColor);
				setUtilsColor(data.utilsColor);
				setNavFontColor(data.navFontColor);
				setIconsColor(data.iconsColor);
				setTitlesColor(data.titlesColor);
				setBtnFontColor(data.btnFontColor);
				ctxDispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (error) {
				ctxDispatch({ type: 'FETCH_FAIL', payload: error.message });
			}
		};
		fetchData();
	}, []);

	function ColorsPreview() {
		document.documentElement.style.setProperty('--primaryColor', primaryColor);
		document.documentElement.style.setProperty(
			'--shadowNavColor',
			shadowNavColor
		);
		document.documentElement.style.setProperty(
			'--secondaryColor',
			secondaryColor
		);
		document.documentElement.style.setProperty(
			'--background-color',
			backgroundColor
		);
		document.documentElement.style.setProperty('--utilsColor', utilsColor);
		document.documentElement.style.setProperty('--navFontColor', navFontColor);
		document.documentElement.style.setProperty('--titlesColor', titlesColor);
		document.documentElement.style.setProperty('--btnFontColor', btnFontColor);
	}

	const setColors = async () => {
		if (userInfo && userInfo.token) {
			ColorsPreview();
			const newColors = {
				primaryColor,
				shadowNavColor,
				secondaryColor,
				backgroundColor,
				utilsColor,
				navFontColor,
				iconsColor,
				titlesColor,
				btnFontColor
			};
			try {
				ctxDispatch({ type: 'CREATE_REQUEST' });
				const data = await colorService.updateColors(newColors);
				ctxDispatch({ type: 'UPDATE_SUCCESS', payload: data });
				toast.success('Tema actualizado Correctamente');
			} catch (error) {
				if (getStatus(error) == 401) {
					redirect();
				} else {
					ctxDispatch({ type: 'FETCH_FAIL' });
					console.error(error.message);

					toast.error('Error actualizando tema.');
				}
			}
		} else {
			redirect();
		}
	};
	const redirect = () => {
		ctxDispatch({ type: 'USER_SIGNOUT' });
		navigate('/signin');
		toast.error('Sesion expirada. Vuelve a ingresar.');
	};
	return (
		<div className="color-config">
			<div className="container  ps-0 mt-2">
				<Form onChange={ColorsPreview()}>
					<Row>
						<Col>
							<Form.Label>Color Principal</Form.Label>
						</Col>
						<Col>
							<InputGroup>
								<Form.Control
									id="primaryColor"
									value={primaryColor}
									type="color"
									onChange={(e) => setPrimaryColor(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Sombreado NavBar</Form.Label>
						</Col>
						<Col>
							<InputGroup>
								<Form.Control
									id="shadowColor"
									type="color"
									value={shadowNavColor}
									onChange={(e) => setShadowNavColor(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Color Secundario</Form.Label>
						</Col>
						<Col>
							<InputGroup>
								<Form.Control
									id="secondaryColor"
									type="color"
									value={secondaryColor}
									onChange={(e) => setSecondaryColor(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Color Fondo</Form.Label>
						</Col>
						<Col>
							<InputGroup>
								<Form.Control
									id="backgroundColor"
									type="color"
									value={backgroundColor}
									onChange={(e) => setBackgroundColor(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Color utilidades</Form.Label>
						</Col>
						<Col>
							<InputGroup>
								<Form.Control
									id="utilsColor"
									type="color"
									value={utilsColor}
									onChange={(e) => setUtilsColor(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Color de Fuente</Form.Label>
						</Col>
						<Col>
							<InputGroup>
								<Form.Control
									id="fontColor"
									type="color"
									value={navFontColor}
									onChange={(e) => setNavFontColor(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Color de Iconos</Form.Label>
						</Col>
						<Col>
							<InputGroup>
								<Form.Control
									id="iconsColor"
									type="color"
									value={iconsColor}
									onChange={(e) => setIconsColor(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Color de Titulos</Form.Label>
						</Col>
						<Col>
							<InputGroup>
								<Form.Control
									id="titlesColor"
									type="color"
									value={titlesColor}
									onChange={(e) => setTitlesColor(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Color de fuente Botones</Form.Label>
						</Col>
						<Col>
							<InputGroup>
								<Form.Control
									id="btnFontColor"
									type="color"
									value={btnFontColor}
									onChange={(e) => setBtnFontColor(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button md={2} onClick={setColors}>
								Actualizar
							</Button>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	);
};
export default ColorConfig;
