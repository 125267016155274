import wodService from '../../services/wod.service';
import React, { useEffect, useReducer, useState } from 'react';
import { Container, Row, Col, Image, Card } from 'react-bootstrap';
import { BsCalendar4Event } from 'react-icons/bs';
import { Helmet } from 'react-helmet-async';
import { getError } from '../../utils';
import LoadingBox from '../../components/LoadingBox';
import '../Screens.css';
import '../../App.css';
import es from 'date-fns/locale/es';
import { format, startOfWeek, addDays } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { DateTime } from 'luxon';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, WOD: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function HomeScreen() {
	const [{ loading, WOD }, dispatch] = useReducer(reducer, {
		loading: true,
		error: '',
		WOD: []
	});

	const today = new Date();
	const [date, setDate] = useState(DateTime.now().toFormat('dd/MM/yyyy'));
	const [wodDates, setWodDates] = useState([]);

	const formattedDay = format(today, 'EEEE,', {
		locale: es
	});
	const formattedDate = format(today, 'd \'de\' MMMM \'de\' yyyy', { locale: es });
	const [daysOfWeek, setDaysOfWeek] = useState([]);

	useEffect(() => {
		const fetchWodDates = async () => {
			try {
				const data = await wodService.getAllWods();
				const dates = data.map((wod) => wod.date);
				setWodDates(dates);
			} catch (error) {
				console.error(error);
			}
		};
		fetchWodDates();
	}, []);

	const isDateInArray = (day) => {
		if (wodDates && wodDates.length > 0) {
			return wodDates.includes(DateTime.fromJSDate(day).toFormat('dd/MM/yyyy'));
		} else {
			return false;
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const data = await wodService.getWodByDate(date);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		fetchData();
	}, [date]);

	useEffect(() => {
		const today = toDate(new Date(), 'America/Montevideo');
		const start = startOfWeek(today, { weekStartsOn: 1 }); // Start on Monday
		const days = [];

		for (let i = 0; i < 7; i++) {
			const date = addDays(start, i);
			days.push(date);
		}

		setDaysOfWeek(days);
	}, []);

	const handleDayButtonClick = (day) => {
		setDate(DateTime.fromJSDate(day).toFormat('dd/MM/yyyy'));
	};

	const dayButtons = daysOfWeek.map((day, i) => (
		<Col
			md={1}
			sm={1}
			lg={1}
			className="d-flex justify-content-center flex-wrap flex-row days-col-btn"
			key={i}
		>
			<button
				className="btn btn-outline-primary"
				key={day}
				onClick={() => handleDayButtonClick(day)}
				style={{
					fontWeight:
						format(day, 'd \'de\' MMMM \'de\' yyyy', { locale: es }) ===
						format(today, 'd \'de\' MMMM \'de\' yyyy', { locale: es })
							? 'bold'
							: 'normal',
					backgroundColor:
						format(day, 'd \'de\' MMMM \'de\' yyyy', { locale: es }) ===
						format(today, 'd \'de\' MMMM \'de\' yyyy', { locale: es })
							? '#169BA0'
							: 'transparent',
					borderColor: 'black',
					color: 'black'
				}}
				disabled={!isDateInArray(day)}
			>
				<Row className="d-flex justify-content-center">
					{format(day, 'E', { locale: es })}
				</Row>
				<Row className="d-flex justify-content-center">
					<h2> {format(day, 'd', { locale: es })}</h2>
				</Row>
			</button>
		</Col>
	));

	return (
		<div>
			<Helmet>
				<title>GotaBooking</title>
			</Helmet>
			<div className="container admin-con">
				<Container
					className=" my-1 justify-content-center align-items-center w-100"
					fluid
				>
					<article>
						<Card
							className="m-0 p-1 pb-3 rounded"
							style={{ cursor: 'default', backgroundColor: '(108, 122, 137)' }}
							ba
							border
						>
							<Row className="justify-content-center align-items-center">
								<Col sm={10} md={10} className="px-0">
									<div className="p-2 m-auto">
										<Row className="p-2 d-flex w-100 justify-content-center justify-content-lg-center flex-row flex-nowrap flex-wrap">
											{dayButtons}
											<Col
												md={1}
												sm={1}
												lg={1}
												className="d-flex justify-content-center flex-nowrap flex-row days-col-btn"
											>
												<button
													className="btn btn-outline-primary"
													style={{
														backgroundColor: '#169BA0',
														borderColor: 'black',
														color: 'black'
													}}
												>
													<Row className="d-flex justify-content-center">
														<h2>
															<BsCalendar4Event></BsCalendar4Event>
														</h2>
													</Row>
												</button>
											</Col>
										</Row>
									</div>
									<Row className="ml-2 p-2 justify-content-around">
										<Col className="d-flex flex-column justify-content-end align-items-start">
											<h1 className="text-left homeTitles">WOD</h1>
											<h2 className="text-left homeTitles">Ejercicio del dia</h2>
										</Col>
										<Col className="d-flex align-items-end justify-content-end flex-column">
											<h1 className="text-right justify-content-end homeTitles">
												<BsCalendar4Event></BsCalendar4Event> &#160; {formattedDay}
											</h1>
											<h2 className="text-right homeTitles">{formattedDate}</h2>
										</Col>
									</Row>

									<Card className=" p-2 container wod-container">
										{loading ? (
											<LoadingBox></LoadingBox>
										) : WOD ? (
											<Col className="d-grid">
												<Image
													className="img-fluid"
													rounded
													src={WOD && WOD.image ? WOD.image.fileLink : null}
												/>
											</Col>
										) : (
											<p>No hay WOD para el dia de hoy</p>
										)}
									</Card>
								</Col>
							</Row>
						</Card>
					</article>
				</Container>
			</div>
		</div>
	);
}
export default HomeScreen;
