import restClient from '../utils/restClient';
import authHeader from './auth-header';
import { handleErrors } from './common.service';

const API_URL = '/bookings';

class BookingTemplateService {
	async getAllBookingTemplates() {
		try {
			const response = await restClient.get(API_URL, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getActiveBookingTemplates() {
		try {
			const response = await restClient.get(API_URL + '/getactive', {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async createBookingTemplate(bookingTemplate) {
		try {
			const response = await restClient.post(API_URL, bookingTemplate, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getBookingTemplateById(id) {
		try {
			const response = await restClient.get(`${API_URL}/${id}`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async updateBookingTemplate(id, bookingTemplate) {
		try {
			const response = await restClient.put(`${API_URL}/${id}`, bookingTemplate, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async toggleActiveStatus(id) {
		try {
			const response = await restClient.put(
				`${API_URL}/toggleactive/${id}`,
				null,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async deleteBookingTemplate(id) {
		try {
			const response = await restClient.delete(`${API_URL}/${id}`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
}

export default new BookingTemplateService();
