import restClient from '../utils/restClient';
import authHeader from './auth-header';
import { handleErrors } from './common.service';

const PAYMENT_API_URL = '/payments';

class PaymentService {
	async getAllPayments() {
		try {
			const response = await restClient.get(PAYMENT_API_URL, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getPaymentsByUserId(userId) {
		try {
			const response = await restClient.get(`${PAYMENT_API_URL}/${userId}`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async createNewPayment(paymentData) {
		try {
			const response = await restClient.post(
				`${PAYMENT_API_URL}/newPayment`,
				paymentData,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async deletePayment(paymentId) {
		try {
			const response = await restClient.delete(`${PAYMENT_API_URL}/${paymentId}`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
}

export default new PaymentService();
