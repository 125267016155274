import restClient from '../utils/restClient';
import authHeader from './auth-header';
import { handleErrors } from './common.service';

const RESULT_API_URL = '/results';

class ResultsService {
	async createResults(wod, wodExercises,user) {
		try {
			const response = await restClient.post(
				RESULT_API_URL,
				{
					wod,
					wodExercises,
					user,
				},
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
}

export default new ResultsService();
