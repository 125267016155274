import axios from 'axios';
import axiosInstance from './utils/restClient';
import { toast } from 'react-toastify';

export const getError = (error) => {
	return error.response && error.response.data.message
		? error.response.data.message
		: error.message;
};
export const regexEmail =
	/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export const maxitemsPerPage = 10;

export const addAlphaToHex = (hex, alpha) => {
	let convertedColor = hex;
	if (hex.length === 6 && alpha > 0 && alpha <= 1) {
		const hexValues = hex.match(/.{1,2}/g);
		const rgbArray = [
			parseInt(hexValues[0], 16),
			parseInt(hexValues[1], 16),
			parseInt(hexValues[2], 16)
		];
		convertedColor = `rgb(${rgbArray[0]},${rgbArray[1]},${rgbArray[2]},${alpha})`;
	}
	return convertedColor;
};

export const getStatus = (error) => {
	return error.response && error.response.status
		? error.response.status
		: error.message;
};

export function getToken() {
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	const token = userInfo ? userInfo.token : null;
	return token;
}

export async function refreshToken() {
	const refresTkn = localStorage.getItem('refreshToken');
	const response = await axiosInstance.post('/auth/refreshToken', { refresTkn });
	const { newRefreshToken, tokenn } = response.data;
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	userInfo.token = tokenn;
	localStorage.removeItem('userInfo');
	localStorage.setItem('userInfo', JSON.stringify(userInfo));
	if (newRefreshToken) {
		localStorage.removeItem('refreshToken');
		localStorage.setItem('refreshToken', newRefreshToken);
	}
	return tokenn;
}
export const showErrorToast = (err) => toast.error(getError(err));

export const getFilterUrl = (fields) => {
	const params = new URLSearchParams();
	for (const fieldKey in fields) {
		const value = fields[fieldKey];
		if(value) {
			params.append(fieldKey, value);
		}
	}
	return params.toString();
};

export const getParamsObj = (searchParams) => {
	const searchParamsKeys = searchParams.keys();
	const query = {};
	for (const key of searchParamsKeys) {
		query[key] = searchParams.get(key);
	}
	return query;
};

export const logoutHandler = async (ctxDispatch) => {
	const refreshToken = localStorage.getItem('refreshToken');
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	if (userInfo && refreshToken) {
		localStorage.setItem('userInfo', null);
		await axios.post('/api/auth/logout', {
			refreshToken,
			id: userInfo._id
		});
	}
	ctxDispatch({ type: 'USER_SIGNOUT' });
};
