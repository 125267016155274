import restClient from '../utils/restClient';
import authHeader from './auth-header';
import { handleErrors } from './common.service';

const SETTINGS_API_URL = '/config';

class SettingsService {
	async getSettings() {
		try {
			const response = await restClient.get(SETTINGS_API_URL, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getPublicSettings() {
		try {
			const response = await restClient.get(`${SETTINGS_API_URL}/public`);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async updateSettings(settingsData) {
		try {
			const response = await restClient.put(SETTINGS_API_URL, settingsData, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async uploadSettingsImage(formData) {
		try {
			const response = await restClient.post(
				`${SETTINGS_API_URL}/uploadSettingsImg`,
				formData,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async updateEmails(newEmails) {
		try {
			const response = await restClient.put(
				`${SETTINGS_API_URL}/emails`,
				{ newEmails },
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getInitialData() {
		try {
			const response = await restClient.get(`${SETTINGS_API_URL}/initData`);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
}

export default new SettingsService();
