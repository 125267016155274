import React, { Suspense, useContext, useEffect } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
// import '@ionic/react/css/core.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { setupAxiosInterceptors } from './utils/restClient.js';
import { logoutHandler } from './utils.js';

//services
import ThemedComponent from './components/GlobalStyles.js';

//components
import Navbarr from './components/Navbar';
import Footerr from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import AdminNavbar from './components/AdminNavbar/AdminNavbar.jsx';
import LoadingBox from './components/LoadingBox';

//Screens
import HomeScreen from './screens/HomeScreens/HomeScreen.jsx';
import SignInScreen from './screens/Auth/SignInScreen.jsx';
import ResetPassword from './screens/Auth/ResetPassword.jsx';
import UpdatePassword from './screens/Auth/ChangePasswordLogged.jsx';
import SignupScreen from './screens/Auth/SignUpScreen.jsx';
import ContactUs from './screens/AboutUs/ContactUs.jsx';
import Shipping from './screens/AboutUs/Shipping.jsx';
import ProfileScreen from './screens/ProfileScreen.jsx';
import AdminHome from './screens/HomeScreens/AdminHome.jsx';
import ChangePassword from './screens/Auth/ChangePassword.jsx';
import VerifyUser from './screens/Auth/VerifyUser.jsx';
import FaqPage from './screens/FaqPage/FaqPage.jsx';
import AdminConfigScreen from './screens/AdminConfigScreen/AdminConfigScreen.jsx';
import AddUser from './screens/Users/NewUser.jsx';
import EditUser from './screens/Users/EditUser.jsx';
import UsersList from './screens/Users/UsersList.jsx';
import UserAttendees from './screens/Users/UserAttendees.jsx';
import BookingList from './screens/BookingTemplate/BookingTemplateList.jsx';
import AddBookingTemplate from './screens/BookingTemplate/newBookingTemplate.jsx';
import EditWOD from './screens/WODs/EditWOD.jsx';
import NewWOD from './screens/WODs/NewWOD.jsx';
import WODList from './screens/WODs/WODList.jsx';
import NewUserBooking from './screens/UserBooking/NewUserBooking.jsx';
import EditUserBooking from './screens/UserBooking/EditUserBooking.jsx';
import EditBookingTemplate from './screens/BookingTemplate/EditBookingTemplate.jsx';
import UserBookingList from './screens/UserBooking/UserBookingList.jsx';
import UserBookingDetails from './screens/UserBooking/UserBookingDetails.jsx';
import AdminEventList from './screens/AdminEvent/AdminEventList.jsx';
import NewEvent from './screens/AdminEvent/NewEvent.jsx';
import EditEvent from './screens/AdminEvent/EditEvent.jsx';
import DetailEvent from './screens/AdminEvent/DetailEvent.jsx';
import UserAvailableEvents from './screens/UserCalendars/UserAvailableEvents.jsx';
import UserEventDetails from './screens/UserCalendars/UserEventDetails.jsx';
import UserDataConfig from './screens/UserSettings/UserDataConfig.jsx';
import DebtList from './screens/AdminDebts/DebtsList.jsx';
import UserDebtsList from './screens/AdminDebts/UserDebtsList.jsx';
import NewPayment from './screens/AdminPayments/NewPayment.jsx';
import PaymentsList from './screens/AdminPayments/PaymentsList.jsx';
import UserPaymentsList from './screens/AdminPayments/UserPaymentsList.jsx';

import { Store } from './Store';
import ExerciseList from './screens/AdminExercises/ExerciseList.jsx';
import AddUserResults from './screens/UserResults/AddUserResults.jsx';

function App() {
	// const init = async () => {
	// 	try {
	// 		const colorResponse = await ColorService.getColors();
	// 		setColors(colorResponse.data);
	// 		setLoading(false);
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };

	//getting theme colors-to do: load before page
	// useLayoutEffect(() => {
	// 	init();
	// }, []);

	const isEmpty = (obj) => {
		return !obj || Object.keys(obj).length === 0;
	};

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	/*function requireAuth() {
    if (!userInfo) {
      //toast.error('Requiere usuario loggeado');
      return false
    }
    return true;
  }*/

	useEffect(() => {
		if (isEmpty(userInfo) || !userInfo) {
			logoutHandler(ctxDispatch);
			ctxDispatch({ type: 'USER_SIGNOUT' });
		}
		setupAxiosInterceptors(ctxDispatch);
	}, [ctxDispatch]);

	return (
		<Suspense fallback={<LoadingBox />}>
			<div>
				<div>
					<ThemedComponent />
					<BrowserRouter>
						<ScrollToTop></ScrollToTop>
						<div className="d-flex flex-column site-container">
							{userInfo?.isAdmin ? <AdminNavbar></AdminNavbar> : <Navbarr></Navbarr>
							}
							<main>
								<Routes>
									<Route path="/" element={<HomeScreen />}></Route>
									<Route path="/AdminScreen/Home" element={<HomeScreen />}></Route>
									<Route path="/signin" element={<SignInScreen />}></Route>
									<Route path="/reset-password/" element={<ResetPassword />}></Route>
									<Route path="/signup" element={<SignupScreen />}></Route>
									<Route path="/profile" element={<ProfileScreen />}></Route>
									<Route
										path="/change-password/:token"
										element={<ChangePassword />}
									></Route>
									<Route
										path="/updatepassword"
										element={userInfo ? <UpdatePassword /> : <Navigate to="/" />}
									></Route>
									<Route path="/AddUserResults" element={userInfo ? <AddUserResults />: <Navigate to="/" />}
									></Route>
									<Route path="/verify-user/:token" element={<VerifyUser />}></Route>
									<Route
										path="/userDataConfig"
										element={userInfo ? <UserDataConfig /> : <Navigate to="/" />}
									></Route>
									<Route path="/contact" element={<ContactUs />}></Route>
									<Route path="/shipping" element={<Shipping />}></Route>
									<Route
										path="/AdminScreen"
										element={userInfo ? <AdminHome /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/newUser"
										element={userInfo ? <AddUser /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/user/:id"
										element={userInfo ? <EditUser /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/editUser/:id"
										element={userInfo ? <EditUser /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/editBookingTemplate/:id"
										element={userInfo ? <EditBookingTemplate /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/users"
										element={userInfo ? <UsersList /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/bookings"
										element={userInfo ? <BookingList /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/userbookinglist/:id"
										element={userInfo ? <UserBookingList /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/userbookingdetails/:id"
										element={userInfo ? <UserBookingDetails /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/userattendees/:id"
										element={userInfo ? <UserAttendees /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/edituserbooking/:id"
										element={userInfo ? <EditUserBooking /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/newuserbooking/:id"
										element={userInfo ? <NewUserBooking /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/newBookingTemplate"
										element={userInfo ? <AddBookingTemplate /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/Config"
										element={userInfo ? <AdminConfigScreen /> : <Navigate to="/" />}
									></Route>
									<Route path="/faq" element={<FaqPage />}>
										+{' '}
									</Route>
									<Route
										path="/AdminScreen/WODs"
										element={userInfo ? <WODList /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/exercises"
										element={userInfo ? <ExerciseList /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/WODs"
										element={userInfo ? <WODList /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/newWOD"
										element={userInfo ? <NewWOD /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/editWOD/:id"
										element={userInfo ? <EditWOD /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/eventList"
										element={userInfo ? <AdminEventList /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/newEvent"
										element={userInfo ? <NewEvent /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/editEvent/:id"
										element={userInfo ? <EditEvent /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/detailEvent/:id"
										element={userInfo ? <DetailEvent /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/useravailableevents"
										element={userInfo ? <UserAvailableEvents /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/userEventDetails/:id"
										element={userInfo ? <UserEventDetails /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/debts"
										element={userInfo ? <DebtList /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/debts/userDebtsList/:id"
										element={userInfo ? <UserDebtsList /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/debts/newpayment/:id"
										element={userInfo ? <NewPayment /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/debts/payments"
										element={userInfo ? <PaymentsList /> : <Navigate to="/" />}
									></Route>
									<Route
										path="/AdminScreen/debts/payments/:userId"
										element={userInfo ? <UserPaymentsList /> : <Navigate to="/" />}
									></Route>
								</Routes>
							</main>
						</div>
						<Footerr></Footerr>
					</BrowserRouter>
				</div>
			</div>
		</Suspense>
	);
}

export default App;
