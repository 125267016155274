import restClient from '../utils/restClient';
import authHeader from './auth-header';
import { handleErrors } from './common.service';
import { DateTime } from 'luxon';

const EVENT_API_URL = '/events';

class EventService {
	async getAllEvents() {
		try {
			const response = await restClient.get(EVENT_API_URL, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getFilteredEvents(subjectQuery, dateSort, startDate, endDate) {
		try {
			const response = await restClient.get(`${EVENT_API_URL}/filtered`, {
				headers: authHeader(),
				params: {
					subjectQuery,
					dateSort,
					startDate: DateTime.fromISO(startDate).toISO(),
					endDate: DateTime.fromISO(endDate).toISO()
				}
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getActiveEvents() {
		try {
			const response = await restClient.get(`${EVENT_API_URL}/getactive`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async createEvent(eventData) {
		try {
			const response = await restClient.post(EVENT_API_URL, eventData, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getEventById(eventId) {
		try {
			const response = await restClient.get(`${EVENT_API_URL}/find/${eventId}`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async updateEvent(eventId, eventData) {
		try {
			const response = await restClient.put(
				`${EVENT_API_URL}/${eventId}`,
				eventData,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async toggleEventActiveStatus(eventId) {
		try {
			const response = await restClient.put(
				`${EVENT_API_URL}/toggleactive/${eventId}`,
				{},
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getCalendarData(start, end) {
		try {
			const response = await restClient.get(
				`${EVENT_API_URL}/calendarData/${start}/${end}`,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getCalendarDataAdmin(eventId) {
		try {
			const response = await restClient.get(
				`${EVENT_API_URL}/calendarDataAdmin/${eventId}`,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
}

export default new EventService();
