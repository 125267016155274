import React from 'react';
import { FaHome, FaUsers, FaCog, FaChartBar } from 'react-icons/fa';
import { RiCoupon2Fill } from 'react-icons/ri';
import { BsFillFileEarmarkFill, BsFillCalendarEventFill } from 'react-icons/bs';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import './AdminNavbarOptions.css';
import { FaDumbbell } from 'react-icons/fa';
import classnames from 'classnames';
export default function AdminNavbarOptions({ imageURL, companyName, handleCollapse }) {

	const { pathname } = useLocation();

	return (
		<div id="mobile-menu-admin">
			<div className="admin-company-logo d-flex justify-content-center w-100">
				<Link to="/" className='d-inline-block h-100'>
					<img className="img-fluid h-100" src={imageURL} alt={`${companyName || ''} Logo`} />
				</Link>
			</div>
			<div className="nav-option">
				<Link to="/AdminScreen" onClick={handleCollapse}>
					<FaChartBar />
					<span>Admin Inicio</span>
				</Link>
			</div>
			<div className="nav-option" onClick={handleCollapse}>
				<Link to="/AdminScreen/users" className={classnames('', { 'focused-option': pathname.includes('AdminScreen/users') })} >
					<FaUsers />
					<span>Usuarios</span>
				</Link>
			</div>

			<div className="nav-option">
				<Link to="/AdminScreen/bookings" className={classnames('', { 'focused-option': pathname.includes('AdminScreen/bookings') })} onClick={handleCollapse}>
					<RiCoupon2Fill />
					<span>Cuponeras</span>
				</Link>
			</div>

			<div className="nav-option">
				<Link to="/AdminScreen/WODs" className={classnames('', { 'focused-option': pathname.includes('AdminScreen/WODs') })} onClick={handleCollapse}>
					<BsFillFileEarmarkFill />
					<span>WODs</span>
				</Link>
			</div>

			<div className="nav-option">
				<Link to="/AdminScreen/exercises" className={classnames('', { 'focused-option': pathname.includes('AdminScreen/exercises') })} onClick={handleCollapse}>
					<FaDumbbell />
					<span>Ejercicios</span>
				</Link>
			</div>

			<div className="nav-option">
				<Link to="/AdminScreen/eventList" className={classnames('', { 'focused-option': pathname.includes('AdminScreen/eventList') })}  onClick={handleCollapse}>
					<BsFillCalendarEventFill />
					<span>Calendario</span>
				</Link>
			</div>

			<div className="nav-option">
				<Link to="/AdminScreen/debts" className={classnames('', { 'focused-option': pathname.includes('AdminScreen/debts') })} onClick={handleCollapse}>
					<FaFileInvoiceDollar />
					<span>Deudas</span>
				</Link>
			</div>

			<div className="nav-option">
				<Link to="/AdminScreen/Home" className={classnames('', { 'focused-option': pathname.includes('AdminScreen/Home') })} onClick={handleCollapse}>
					<FaHome />
					<span>Inicio</span>
				</Link>
			</div>

			<div className="nav-option">
				<Link to="/AdminScreen/Config" className={classnames('', { 'focused-option': pathname.includes('AdminScreen/Config') })} onClick={handleCollapse} >
					<FaCog />
					<span>Configuracion</span>
				</Link>
			</div>
		</div>
	);
}
