import Container from 'react-bootstrap/Container';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Store } from '../Store';
import { Helmet } from 'react-helmet-async';
import {
	Row,
	Col,
	Card,
	Button,
	Image,
	Table,
	ListGroup,
	ListGroupItem,
	OverlayTrigger,
	Tooltip
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import eventAttendeeService from '../services/eventAttendee.service';
import userBookingService from '../services/userBooking.service';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, events: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, error: action.payload };
	case 'FETCH_REQUEST_COUNT':
		return { ...state };
	case 'FETCH_SUCCESS_COUNT':
		return { ...state, bookingsLeft: action.payload };
	case 'FETCH_FAIL_COUNT':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function ProfileScreen() {
	const { state } = useContext(Store);
	const { userInfo } = state;
	const [name, setName] = useState(userInfo.name);

	const [{ events, successDelete, bookingsLeft }, dispatch] = useReducer(
		reducer,
		{
			loading: false,
			events: [],
			bookingsLeft: []
		}
	);

	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const data = await eventAttendeeService.getUserPendingEvents(userInfo._id);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};

		const fetchCountData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST_COUNT' });
				const data = await userBookingService.getUserBookingsLeft(userInfo._id);
				dispatch({ type: 'FETCH_SUCCESS_COUNT', payload: data.count });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL_COUNT', payload: getError(err) });
			}
		};

		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
			fetchCountData();
			setName(userInfo.name);
		}
	}, [successDelete, userInfo.token]);

	async function unsuscribeEventHandler(event) {
		if (window.confirm('Seguro desea desinscribirse a este evento?')) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await eventAttendeeService.unsubscribeEventAttendee(
					userInfo._id,
					event._id
				);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success(`Te desinscribiste a el evento ${event.subject}`);
			} catch (err) {
				toast.error('No lograste desinscribiste al evento. Intentelo mas tarde.');
				dispatch({ type: 'DELETE_FAIL' });
			}
		}
	}

	async function changePasswordEventHandler() {
		navigate('/updatepassword');
	}

	async function updateUserDataEventHandler() {
		navigate('/userDataConfig');
	}

	return (
		<Container className="">
			<Helmet>
				<title>Perfil</title>
			</Helmet>
			<h1 className="my-3">Perfil</h1>
			<section className="w-100 p-4">
				<Row>
					<Col className="h-100 col-12 col-lg-4">
						<Card className="mb-4">
							<div className="card-body text-center">
								<Image
									src="/images/user-default-image.png"
									alt="avatar"
									className="mx-auto mx-lg-0 h-100 col-8 col-sm-6 col-md-4 col-lg-1 my-auto"
									fluid
									roundedCircle
									style={{ width: '100%' }}
								></Image>
							</div>
						</Card>
					</Col>
					<Col className="col-12 col-lg-8">
						<Card className="mb-4">
							<h1>{name}</h1>
							<h5>Clases restantes: {bookingsLeft}</h5>
							<div className="d-flex justify-content-center mb-2">
								<Button
									value="Editar perfil"
									className="btn btn-dark m-1"
									onClick={() => updateUserDataEventHandler()}
								>
									<span>Editar perfil</span>
								</Button>
								<Button
									value="Cambiar contraseña"
									onClick={() => changePasswordEventHandler()}
									className="btn btn-dark m-1"
								>
									<span>Cambiar contraseña</span>
								</Button>
							</div>
						</Card>
					</Col>
				</Row>
				<Row>
					{events.length > 0 ? (
						<Table className="col-12">
							<ListGroup>
								<ListGroupItem>
									<Row className="align-items-center table-order">
										<Col as={'th'} md={4}>
											<span>Clase</span>
										</Col>
										<Col as={'th'} md={3}>
											<span>Horario</span>
										</Col>
										<Col as={'th'} md={3}>
											<span>Ubicacion</span>
										</Col>
										<Col md={2}></Col>
									</Row>
								</ListGroupItem>
							</ListGroup>
							<ListGroup>
								{events.map((event) => (
									<ListGroupItem key={event._id}>
										<Row
											className={`align-items-center table-order ${
												event.isActive ? 'table-success' : 'table-secondary'
											}`}
											id="data"
										>
											<Col md={4}>{event.subject}</Col>
											<Col
												md={3}
											>{`${event.eventStartDate} de ${event.eventStartTime} a ${event.eventEndTime}`}</Col>
											<Col md={3}>{event.location}</Col>
											<Col md={2}>
												{moment(event.eventStartDateTime) > moment() && (
													<OverlayTrigger
														key="top"
														placement="top"
														overlay={<Tooltip id={'tooltip-top'}>Cancelar</Tooltip>}
													>
														<Button
															md={4}
															variant="outline-danger"
															className="mr-1"
															//className="btn btn-outline-danger"
															onClick={() => unsuscribeEventHandler(event)}
														>
															Cancelar
														</Button>
													</OverlayTrigger>
												)}
											</Col>
										</Row>
									</ListGroupItem>
								))}
							</ListGroup>
						</Table>
					) : (
						<Col className="col-12">
							<Card className="mb-4">
								<h1>No hay clases para hoy o en futuro registradas</h1>
							</Card>
						</Col>
					)}
				</Row>
			</section>
		</Container>
	);
}
export default ProfileScreen;
