import React from 'react';
import { Link } from 'react-router-dom';
import './NavbarOptions.css';

export default function NavbarOptions({ handleCollapse, userInfo }) {
	return (
		<ul className="admin-navbar-options">
			<li>
				<Link to="/" onClick={handleCollapse} className="nav-link">
					<h3>Inicio</h3>
				</Link>
			</li>
			{userInfo ? (
				<div>
					<li>
						<Link
							to="/useravailableevents"
							onClick={handleCollapse}
							className="nav-link"
						>
							<h3>Reservar Clase</h3>
						</Link>
					</li>
					<li>
						<Link
							to="/addUserResults"
							onClick={handleCollapse}
							className="nav-link"
						>
							<h3>Agregar Resultados Ejercicios</h3>
						</Link>
					</li>
				</div>
			) : null}
		</ul>
	);
}
