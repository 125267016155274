import LoadingBox from '../../components/LoadingBox';
import React, {
	useContext,
	useReducer,
	useState,
	useEffect,
	useRef
} from 'react';
import { Store } from '../../Store';
import { Helmet } from 'react-helmet-async';
import {
	Button,
	Col,
	Container,
	Row,
	Card,
	OverlayTrigger,
	Tooltip
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getError } from '../../utils';
import eventService from '../../services/event.service';
import eventAttendeeService from '../../services/eventAttendee.service';
import { useParams } from 'react-router-dom';
import { BsTrash, BsPlus } from 'react-icons/bs';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, event: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'FETCH_REQUEST_ATTENDEE':
		return { ...state, loadingAttendee: true };
	case 'FETCH_SUCCESS_ATTENDEE':
		return { ...state, users: action.payload, loadingAttendee: false };
	case 'FETCH_FAIL_ATTENDEE':
		return { ...state, loadingAttendee: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };
	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	case 'INSCRIPTION_REQUEST':
		return { ...state, loadingInscription: true, successInscription: false };
	case 'INSCRIPTION_SUCCESS':
		return {
			...state,
			loadingInscription: false,
			successInscription: true
		};
	case 'INSCRIPTION_FAIL':
		return { ...state, loadingInscription: false, successInscription: false };
	case 'INSCRIPTION_RESET':
		return { ...state, loadingInscription: false, successInscription: false };
	case 'USERS_REQUEST':
		return { ...state, loadingAllUsers: true };
	case 'USERS_SUCCESS':
		return { ...state, allusers: action.payload, loadingAllUsers: false };
	case 'USERS_FAIL':
		return { ...state, loadingAllUsers: false, error: action.payload };
	default:
		return state;
	}
};

function detailEvent() {
	const params = useParams();
	const { id } = params;
	const [
		{
			loading,
			loadingAttendee,
			successDelete,
			successInscription,
			event,
			users,
			allusers,
			loadingAllUsers
		},
		dispatch
	] = useReducer(reducer, {
		event: {},
		users: {},
		allusers: [],
		loading: true,
		loadingAttendee: true,
		loadingAllUsers: true,
		error: ''
	});
	const { state } = useContext(Store);
	const { userInfo } = state;
	const [selectedUser, setSelectedUser] = useState();
	const typeaheadRef = useRef();

	function disponibility() {
		if (event.capacity === users.length) {
			return 'bg-warning';
		} else if (event.capacity > users.length) {
			return 'bg-success';
		} else {
			return 'bg-danger';
		}
	}
	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await eventService.getEventById(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};

		const fetchDataAttendee = async () => {
			dispatch({ type: 'FETCH_REQUEST_ATTENDEE' });
			try {
				const result = await eventAttendeeService.getAllEventAttendeesByEventId(id);
				dispatch({ type: 'FETCH_SUCCESS_ATTENDEE', payload: result });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL_ATTENDEE', payload: getError(error) });
			}
		};

		const fetchDataAllUsers = async () => {
			dispatch({ type: 'USERS_REQUEST' });
			try {
				const result = await eventAttendeeService.getNotSignedUsers(id);

				dispatch({ type: 'USERS_SUCCESS', payload: result });
			} catch (error) {
				dispatch({ type: 'USERS_FAIL', payload: getError(error) });
			}
		};
		if (successDelete || successInscription) {
			dispatch({ type: 'DELETE_RESET' });
			dispatch({ type: 'INSCRIPTION_RESET' });
		} else {
			fetchData();
			fetchDataAttendee();
			fetchDataAllUsers();
		}
	}, [successDelete, successInscription, id]);

	async function unsuscribeEventHandler(user) {
		if (
			window.confirm(
				`Seguro desea desinscribir a ${user.name} al evento "${event.subject}"?`
			)
		) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await eventAttendeeService.unsubscribeEventAttendee(user._id, event._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success(`Desinscribiste a ${user.name} al evento "${event.subject}"`);
			} catch (err) {
				toast.error(
					`No lograste desinscribir a ${user.name} al evento "${event.subject}". Intentelo mas tarde.`
				);
				dispatch({ type: 'DELETE_FAIL' });
			}
		}
	}

	async function suscribeEventHandler() {
		const user = selectedUser[0];
		if (
			window.confirm(
				`Seguro desea inscribir a ${user.name} al evento "${event.subject}"?`
			)
		) {
			try {
				dispatch({ type: 'INSCRIPTION_REQUEST' });
				const eventAttendeeData = {
					user: userInfo._id,
					event: event._id
				};
				await eventAttendeeService.createEventAttendee(eventAttendeeData);

				typeaheadRef.current.clear();
				dispatch({ type: 'INSCRIPTION_SUCCESS' });
				toast.success(`Inscribiste a ${user.name} a el evento "${event.subject}"`);
			} catch (err) {
				toast.error(getError(err));
				toast.error(
					`No lograste inscribir a ${user.name} a el evento "${event.subject}". Intentelo mas tarde.`
				);
				dispatch({ type: 'INSCRIPTION_FAIL' });
			}
		}
	}

	return (
		<div>
			{loading && loadingAttendee && loadingAllUsers && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Container className="large-container">
				<Helmet>
					<title>Detalles {`${event.subject}`}</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="border border-3 border-primary"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center">
											{event.subject}
										</h2>
										<div className="mb-3">
											<div className="mb-3">
												<Container>
													<Row>
														<Col>Locacion: </Col>
														<Col>{event.location}</Col>
													</Row>
													<Row>
														<Col>Fecha de inicio: </Col>
														<Col>
															{moment(event.eventStartDateTime).format('DD/MM/YYYY HH:mm')}
														</Col>
													</Row>
													<Row>
														<Col>Fecha de fin: </Col>
														<Col>
															{moment(event.eventEndDateTime).format('DD/MM/YYYY HH:mm')}
														</Col>
													</Row>
													<Row>
														<Col
															className={`text-white rounded-3 ${event.isActive ? 'bg-success' : 'bg-danger'}`}
														>
															{event.isActive
																? 'El evento se encuentra activo'
																: 'El evento no se encuentra activo'}
														</Col>
													</Row>
													<p></p>
													<Row>
														<Container className={`text-white rounded-3 ${disponibility()}`}>
															<Row>
																<Col>
																	<h1 className="fw-bold mb-4 text-uppercase text-align-center">
																		Cupos
																	</h1>
																</Col>
															</Row>
															<Row>
																<Col>
																	<Row>
																		<h2
																			className={`fw-bold mb-4 text-uppercase text-align-center ${disponibility()}`}
																		>
																			Capacidad disponible: {users.length}/{event.capacity}
																		</h2>
																	</Row>
																</Col>
															</Row>
														</Container>
													</Row>
													<Row>
														<Container
															className={`text-white rounded-3 bg-opacity-75 mt-1 ${disponibility()}`}
														>
															{users.length === 0 &&
																event.capacity - users.length > 0 &&
																moment(event.eventStartDateTime) > moment() && (
																<Row className="mt-1">
																	<Col className="h3">No hay inscriptos</Col>
																</Row>
															)}
															{event.capacity - users.length <= 0 &&
																moment(event.eventStartDateTime) > moment() && (
																<Row className="mt-1">
																	<Col className="h3">No hay cupos disponibles</Col>
																</Row>
															)}
															{moment(event.eventEndDateTime) < moment() && (
																<Row className="mt-1">
																	<Col className="h3">Evento finalizado</Col>
																</Row>
															)}
															{moment(event.eventStartDateTime) < moment() &&
																moment(event.eventEndDateTime) > moment() && (
																<Row className="mt-1">
																	<Col className="h3">El evento ya ha comenzado</Col>
																</Row>
															)}
															{users.length > 0 && (
																<Row className="mt-1">
																	<Col className="h3">Inscriptos:</Col>
																</Row>
															)}
															{Array.isArray(users)
																? users.map((user) =>
																	user.user ? ( //check user
																		<Row key={user._id}>
																			<Col>
																				{`${user.user.name} - ${user.user.email}`}
																				{moment(event.eventEndDateTime) > moment() && (
																					<OverlayTrigger
																						key="top"
																						placement="top"
																						overlay={<Tooltip id={'tooltip-top'}>Eliminar</Tooltip>}
																					>
																						<Button
																							md={4}
																							variant="outline-danger"
																							className="float-end mr-1 "
																							onClick={() => unsuscribeEventHandler(user.user)}
																						>
																							<BsTrash />
																						</Button>
																					</OverlayTrigger>
																				)}
																			</Col>
																		</Row>
																	) : null
																)
																: null}
															{event.capacity - users.length > 0 &&
																event.isActive &&
																moment(event.eventStartDateTime) > moment() && (
																<Row className="mb-2">
																	<Col md={11}>
																		<Typeahead
																			id="users"
																			ref={typeaheadRef}
																			labelKey={(option) => `${option.name} ${option.email}`}
																			onChange={setSelectedUser}
																			options={allusers}
																			placeholder="Seleccione un usuario a inscribir"
																			clearButton={true}
																			disabled={moment(event.eventStartDateTime) < moment()}
																			emptyLabel="No hay usuarios disponibles"
																		></Typeahead>
																	</Col>
																	<Col md={1}>
																		<Button
																			md={4}
																			variant="outline-primary"
																			className="float-end mr-1 "
																			onClick={() => suscribeEventHandler()}
																		>
																			<BsPlus />
																		</Button>
																	</Col>
																</Row>
															)}
														</Container>
													</Row>
												</Container>
											</div>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default detailEvent;
