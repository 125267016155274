import restClient from '../utils/restClient';
import { handleErrors } from './common.service';

const UTILS_API_URL = '/utils';

class UtilsService {
	async getIframeUrl() {
		try {
			const response = await restClient.get(`${UTILS_API_URL}/iframe`);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
}

export default new UtilsService();
