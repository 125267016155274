import userBookingService from '../../services/userBooking.service';
import userService from '../../services/user.service';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Row,
	Col,
	Button,
	Table,
	Dropdown,
	DropdownButton,
	Card
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { getError, maxitemsPerPage } from '../../utils';
import { Store } from '../../Store';
import moment from 'moment';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination';
import {
	BsTrash,
	BsPencilSquare,
	BsFileEarmarkRichtext,
	BsFillBackspaceFill
} from 'react-icons/bs';
import { RiCoupon2Fill } from 'react-icons/ri';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			bookings: action.payload,
			loading: false,
			itemQuantity: action.payload.length
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'FETCH_USER_REQUEST':
		return { ...state };
	case 'FETCH_USER_SUCCESS':
		return { ...state, user: action.payload };
	case 'FETCH_USER_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function UserBookingList() {
	const params = useParams();
	const { id } = params;
	const [
		{ loading, error, bookings, user, successDelete, itemQuantity },
		dispatch
	] = useReducer(reducer, {
		user: [],
		bookings: [],
		loading: true,
		itemQuantity: 0,
		error: ''
	});

	const { state } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = bookings.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = bookings.slice(indexOfFirstPost, indexOfLastPost);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const data = await userBookingService.getUserBookingsByUserId(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		const fetchUserData = async () => {
			try {
				dispatch({ type: 'FETCH_USER_REQUEST' });
				const data = await userService.getUserById(id);
				dispatch({ type: 'FETCH_USER_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_USER_FAIL', payload: getError(err) });
			}
		};

		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
		fetchUserData();
	}, [successDelete, userInfo.token, id]);

	async function toggleUserBookingHandler(booking) {
		if (
			window.confirm(
				`Seguro desea ${booking.isActive ? 'desactivar' : 'activar'} esta cuponera?`
			)
		) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await userBookingService.toggleUserBookingStatus(booking._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success(
					`Cuponera de ${booking.template.name} ${booking.isActive ? 'desactivada' : 'activada'}`
				);
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
			}
		}
	}

	async function editUserBookingHandler(booking) {
		navigate(`/AdminScreen/editUserBooking/${booking._id}`);
	}

	async function detailsUserBookingHandler(booking) {
		navigate(`/AdminScreen/userbookingdetails/${booking._id}`);
	}

	async function newUserBookingHandler() {
		navigate(`/AdminScreen/newuserbooking/${id}`);
	}
	async function goUserDebtsList() {
		navigate(`/AdminScreen/debts/userDebtsList/${id}`);
	}

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<div>
			<Helmet>Cuponeras de:{user.name}</Helmet>

			<div className="container admin-con">
				<div className="tableResponsive">
					<Row style={{ margin: '5% 0 2.5%' }}>
						<Col>
							<h1 className="text-right">
								<RiCoupon2Fill></RiCoupon2Fill>Cuponeras de {user.name}
							</h1>
						</Col>
						<Col
							style={{
								display: 'flex',
								justifyContent: 'flex-end'
							}}
						>
							<Button
								className="btn btn-dark m-1 fixed-left"
								value="Volver"
								onClick={() => newUserBookingHandler()}
							>
								<span>Asignar Cuponera</span>
							</Button>
							<Button
								className="btn btn-dark m-1 fixed-left"
								value="Volver"
								onClick={() => goUserDebtsList()}
							>
								<span>Gestionar Deudas</span>
							</Button>
							<Button
								className="btn btn-dark m-1 fixed-left"
								value="Volver"
								onClick={() => navigate(-1)}
							>
								<span>
									<BsFillBackspaceFill /> Volver
								</span>
							</Button>
						</Col>
					</Row>
					{loading ? (
						<LoadingBox></LoadingBox>
					) : error ? (
						<MessageBox variant="danger">{error}</MessageBox>
					) : (
						<Card>
							<Table bordered hover responsive size="sm">
								<thead>
									<th className="col-md-2">
										<span>Cuponera</span>
									</th>
									<th className="col-md-2">Clases restantes</th>
									<th className="col-md-2">Estado</th>
									<th className="col-md-2">Fecha de inicio</th>
									<th className="col-md-2">Fecha de vencimiento</th>
									<th className="col-1">Opciones</th>
								</thead>
								{bookings
									? currentPosts.map((booking) => (
										<tbody key={booking._id}>
											<tr
												className={`align-items-center table-order ${
													booking.isActive ? '' : 'table-secondary'
												}`}
												id="data"
											>
												<td className="col-md-2">{booking.template.name}</td>
												<td className="col-md-2">{booking.bookingsLeft}</td>
												<td className="col-md-2">{`${booking.isActive ? 'activa' : 'inactiva'}`}</td>
												<td className="col-md-2">
													{moment(booking.startingDate).format('DD/MM/YYYY')}
												</td>
												<td className="col-md-2">
													{moment(booking.dueDate).format('DD/MM/YYYY')}
												</td>
												<td>
													<DropdownButton id="optionsLists" drop="start" title="">
														<Dropdown.Item
															eventKey="0"
															onClick={() => detailsUserBookingHandler(booking)}
														>
															<BsFileEarmarkRichtext />
																Ver Detalles
														</Dropdown.Item>
														<Dropdown.Item
															eventKey="1"
															onClick={() => editUserBookingHandler(booking)}
														>
															<BsPencilSquare />
																Editar
														</Dropdown.Item>
														<Dropdown.Item
															eventKey="2"
															onClick={() => toggleUserBookingHandler(booking)}
														>
															<BsTrash />
																Eliminar
														</Dropdown.Item>
													</DropdownButton>
												</td>
											</tr>
										</tbody>
									))
									: ''}
							</Table>
						</Card>
					)}
					<Pagination
						className="pagination-bar"
						totalCount={itemQuantity}
						onPageChange={onPageChange}
						currentPage={currentPage}
						pageSize={maxitemsPerPage}
					></Pagination>
				</div>
			</div>
		</div>
	);
}

export default UserBookingList;
