import React from 'react';
import '../Screens.css';
import { Card, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

function Contacto() {
	return (
		<div className="container container-envios">
			<Helmet>
				<title>Contact Us</title>
			</Helmet>
			<Card>
				<Row className="envios-Row">
					<h1 className="title-col">Contact</h1>
					<p>
						Lorem Ipsum is simply dummy text of the printing and typesetting industry.
						Lorem Ipsum has been the industrys standard dummy text ever since the
						1500s, when an unknown printer took a galley of type and scrambled it to
						make a type specimen book. It has survived not only five centuries, but
						also the leap into electronic typesetting, remaining essentially
						unchanged. It was popularised in the 1960s with the release of Letraset
						sheets containing Lorem Ipsum passages, and more recently with desktop
						publishing software like Aldus PageMaker including versions of Lorem
						Ipsum.
					</p>
				</Row>
			</Card>
		</div>
	);
}

export default Contacto;
