import restClient from '../utils/restClient';
import authHeader from './auth-header';
import { handleErrors } from './common.service';

const DEBT_API_URL = '/debts';

class DebtService {
	async getAllDebts() {
		try {
			const response = await restClient.get(DEBT_API_URL, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getDebtsByUserId(userId) {
		try {
			const response = await restClient.get(`${DEBT_API_URL}/${userId}`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getDebtsByUserBookingId(userBookingId) {
		try {
			const response = await restClient.get(
				`${DEBT_API_URL}/userbooking/${userBookingId}`,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
}

export default new DebtService();
