import React from 'react';
import './AdminConfig.css';
import Accordion from 'react-bootstrap/Accordion';
import EmailConfig from '../EmailConfigScreen/EmailConfig';
import AdminInfo from '../AdminInfo/AdminInfo';
import FaqQuestionsConfig from '../../components/FaqQuestionsConfig/FaqQuestionsConfig';
import ColorConfig from '../../components/ColorConfig/ColorConfig';
import '../../App.css';

export default function AdminConfigScreen() {
	return (
		<div  className="admin-con">
			<h1 className="text-center">Configuración</h1>
			<div className="container config-items">
				<Accordion>
					<Accordion>
						<Accordion.Item eventKey="0">
							<Accordion.Header>Change Company Info</Accordion.Header>
							<Accordion.Body>
								<AdminInfo></AdminInfo>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
					<Accordion.Item eventKey="0">
						<Accordion.Header>Correos Electrónicos del Sistema</Accordion.Header>
						<Accordion.Body>
							<EmailConfig></EmailConfig>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
				<Accordion>
					<Accordion.Item eventKey="0">
						<Accordion.Header>Cambiar Tema</Accordion.Header>
						<Accordion.Body>
							<ColorConfig></ColorConfig>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
				<Accordion>
					<Accordion.Item eventKey="3">
						<Accordion.Header>Preguntas Frecuentes</Accordion.Header>
						<Accordion.Body>
							<FaqQuestionsConfig></FaqQuestionsConfig>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</div>
		</div>
	);
}
