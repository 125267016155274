import LoadingBox from '../../components/LoadingBox';
import React, { useReducer, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
	Form,
	Button,
	Modal,
	Col,
	Container,
	Row,
	Card,
	Stack,
	Table,
	DropdownButton,
	Dropdown
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getError } from '../../utils';
import userBookingService from '../../services/userBooking.service';
import debtService from '../../services/debt.service';
import paymentService from '../../services/payment.service';
import { useNavigate, useParams } from 'react-router-dom';
import { BsFillBackspaceFill } from 'react-icons/bs';
import moment from 'moment';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, booking: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'FETCH_DEBT_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_DEBT_SUCCESS':
		return { ...state, debt: action.payload, loading: false };
	case 'FETCH_DEBT_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function UserBookingDetails() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, booking, debt }, dispatch] = useReducer(reducer, {
		booking: {},
		debt: {},
		loading: true,
		error: ''
	});
	const [dueDate, setDueDate] = useState('');
	const [startingDate, setStartingDate] = useState();
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await userBookingService.getUserBookingById(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result });
				setDueDate(result.dueDate);
				setStartingDate(result.startingDate);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		const fetchDebtData = async () => {
			dispatch({ type: 'FETCH_DEBT_REQUEST' });
			try {
				const result = await debtService.getDebtsByUserBookingId(id);
				dispatch({ type: 'FETCH_DEBT_SUCCESS', payload: result });
			} catch (error) {
				dispatch({ type: 'FETCH_DEBT_FAIL', payload: getError(error) });
			}
		};
		fetchDebtData();
		fetchData();
	}, [id]);

	const addPaymentHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const paymentData = {
				debt: debt[0],
				amount: debt[0].amount // Ensure the correct amount is passed
			};
			await paymentService.createNewPayment(paymentData);
			toast.success('Pago creado');
			navigate(-1);
		} catch (ex) {
			toast.error(getError(ex));
		}
		handleClose();
	};
	return (
		<div>
			{loading && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Crear Pago</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea crear Pago </Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancelar
					</Button>
					<Button onClick={addPaymentHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
			<Container className="large-container">
				<Helmet>
					<title>Ver cuponera</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="border border-3 border-primary"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
											Detalles de Cuponera
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group className="mb-3" controlid="name">
													<Form.Label>Clases restantes:</Form.Label>
													<Form.Control
														type="text"
														required
														disabled
														defaultValue={booking.bookingsLeft}
													></Form.Control>
												</Form.Group>
												<Row>
													<Form.Group className="mb-3 col-6" controlid="duedate">
														<Form.Label>Fecha de Inicio:</Form.Label>
														<Form.Control
															type="date"
															required
															disabled
															value={moment(startingDate).format('YYYY-MM-DD')}
														></Form.Control>
													</Form.Group>
													<Form.Group className="mb-3 col-6" controlid="duedate">
														<Form.Label>Fecha de vencimiento:</Form.Label>
														<Form.Control
															type="date"
															required
															disabled
															value={moment(dueDate).format('YYYY-MM-DD')}
														></Form.Control>
													</Form.Group>
												</Row>
												<Table bordered hover responsive size="sm">
													<thead>
														<h2>Gestion de Deuda</h2>
														<tr>
															<th className="col-md-2">
																<span>Usuario</span>
															</th>
															<th className="col-md-2">Concepto</th>
															<th>Monto</th>
															<th className="col-md-1">Fecha</th>
															<th>Pago</th>
															<th className="col-1">Opciones</th>
														</tr>
													</thead>
													{debt && debt.length > 0 ? (
														<tbody>
															<tr
																className="align-items-center table-order"
																id="data"
																key={debt[0]._id}
															>
																{debt[0].user && debt[0].user.name ? (
																	<td className="col-md-2">{debt[0].user.name}</td>
																) : (
																	<td></td>
																)}
																{debt[0].userbooking ? (
																	<td className="col-md-2">
																		{debt[0].userbooking.template.name}
																	</td>
																) : (
																	<td></td>
																)}
																<td className="col-md-2">${debt[0].amount}</td>
																<td className="col-md-1">
																	{moment(debt[0].date).format('DD-MM-YYYY hh:mm a')}
																</td>
																<td className="col-md-1">
																	{debt[0].paid ? 'confirmado' : 'pendiente'}
																</td>
																<td>
																	<DropdownButton id="optionsLists" drop="start" title="">
																		{/* <Dropdown.Item eventKey="0" onClick={() => detailsUserHandler(user)}><BsFileEarmarkRichtext />Ver Detalles</Dropdown.Item> */}
																		{!debt[0].paid ? (
																			<Dropdown.Item eventKey="0" onClick={handleShow}>
																				Confirmar Pago
																			</Dropdown.Item>
																		) : (
																			<Dropdown.Item eventKey="0" disabled onClick={handleShow}>
																				Confirmar Pago
																			</Dropdown.Item>
																		)}
																		{/* <Dropdown.Item eventKey="2" onClick={() => userAttendeesHandler(user)}><BsFillCalendarEventFill />Ver Inscripciones</Dropdown.Item> */}
																		{/* <Dropdown.Item eventKey="3" onClick={() => deletePaymentHandler(payment)}><BsTrash />Eliminar</Dropdown.Item> */}
																	</DropdownButton>
																</td>
															</tr>
														</tbody>
													) : (
														''
													)}
												</Table>

												<Stack direction="horizontal" gap={10}>
													<Button
														className="btn btn-dark m-1 fixed-left"
														value="Volver"
														onClick={() => navigate(-1)}
													>
														<span>
															<BsFillBackspaceFill /> Volver
														</span>
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default UserBookingDetails;
