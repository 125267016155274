import userService from '../../services/user.service';
import eventAttendeeService from '../../services/eventAttendee.service';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Row,
	Col,
	Button,
	Table,
	Dropdown,
	DropdownButton,
	Card
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { getError, maxitemsPerPage } from '../../utils';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination';
import {
	BsPlusCircle,
	BsTrash,
	BsPencilSquare,
	BsFileEarmarkRichtext,
	BsFillCalendarEventFill
} from 'react-icons/bs';
import { FaUsers } from 'react-icons/fa';
import '../../App.css';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			users: action.payload,
			loading: false,
			itemQuantity: action.payload.length
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function UsersList() {
	const [{ loading, error, users, successDelete, itemQuantity }, dispatch] =
		useReducer(reducer, {
			users: [],
			loading: true,
			error: '',
			itemQuantity: 0
		});

	const { state } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = users.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = users.slice(indexOfFirstPost, indexOfLastPost);
	};
	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const data = await userService.getAllUsers();
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete, userInfo.token]);

	async function deleteUserHandler(user) {
		if (
			window.confirm(
				'Seguro desea eliminar el Usuario? si lo hace eliminara todas las inscripciones y cuponeras vinculadas'
			)
		) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				const userData = { user: user._id };
				await eventAttendeeService.unsubscribeEventAttendee(userData);
				await userService.deleteUser(user._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('Usuario e inscripciones eliminadas');
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
			}
		}
	}

	async function editUserHandler(user) {
		navigate(`/AdminScreen/editUser/${user._id}`);
	}

	async function addUserHandler(user) {
		navigate(`/AdminScreen/newuserbooking/${user._id}`);
	}

	async function detailsUserHandler(user) {
		navigate(`/AdminScreen/userbookinglist/${user._id}`);
	}

	async function newUserHandler() {
		navigate('/AdminScreen/newUser');
	}

	async function userAttendeesHandler(user) {
		navigate(`/AdminScreen/userattendees/${user._id}`);
	}

	return (
		<div>
			{loading ? (
				<LoadingBox></LoadingBox>
			) : error ? (
				<MessageBox variant="danger">{error}</MessageBox>
			) : (
				<div>
					<Helmet>
						<title>Lista de Usuarios</title>
					</Helmet>

					<div className="container admin-con">
						<div className="tableResponsive">
							<Row style={{ margin: '5% 0 2.5%' }}>
								<Col>
									<h1 className="text-right">
										<FaUsers></FaUsers>Lista de Usuarios
									</h1>
								</Col>
								<Col
									style={{
										display: 'flex',
										justifyContent: 'flex-end'
									}}
								>
									<Button
										className="btn btn-dark m-1 fixed-right"
										value="Crear Usuario"
										onClick={() => newUserHandler()}
									>
										<span>
											<BsPlusCircle /> Crear Usuario
										</span>
									</Button>
								</Col>
							</Row>
							{loading ? (
								<LoadingBox></LoadingBox>
							) : error ? (
								<MessageBox variant="danger">{error}</MessageBox>
							) : (
								<Card>
									<Table bordered hover responsive size="sm">
										<thead>
											<tr>
												<th className="col-md-2">
													<span>Nombre</span>
												</th>
												<th className="col-md-2">Email</th>
												<th className="col-md-1">¿Admin?</th>
												<th className="col-1">Opciones</th>
											</tr>
										</thead>
										{users
											? currentPosts.map((user) => (
												<tbody key={user._id}>
													<tr className="align-items-center table-order" id="data">
														<td className="col-md-2">{user.name}</td>
														<td className="col-md-2">{user.email}</td>
														<td className="col-md-1">{user.isAdmin ? 'Si' : 'No'}</td>
														<td>
															<DropdownButton id="optionsLists" drop="start" title="">
																<Dropdown.Item
																	eventKey="0"
																	onClick={() => detailsUserHandler(user)}
																>
																	<BsFileEarmarkRichtext />
																		Ver Detalles
																</Dropdown.Item>
																<Dropdown.Item
																	eventKey="1"
																	onClick={() => addUserHandler(user)}
																>
																	<BsFileEarmarkRichtext />
																		Asignar Cuponeras
																</Dropdown.Item>
																<Dropdown.Item
																	eventKey="2"
																	onClick={() => editUserHandler(user)}
																>
																	<BsPencilSquare />
																		Editar
																</Dropdown.Item>
																<Dropdown.Item
																	eventKey="3"
																	onClick={() => userAttendeesHandler(user)}
																>
																	<BsFillCalendarEventFill />
																		Ver Inscripciones
																</Dropdown.Item>
																<Dropdown.Item
																	eventKey="4"
																	onClick={() => deleteUserHandler(user)}
																>
																	<BsTrash />
																		Eliminar
																</Dropdown.Item>
															</DropdownButton>
														</td>
													</tr>
												</tbody>
											))
											: ''}
									</Table>
								</Card>
							)}
							<Pagination
								className="pagination-bar"
								totalCount={itemQuantity}
								onPageChange={onPageChange}
								currentPage={currentPage}
								pageSize={maxitemsPerPage}
							></Pagination>
						</div>
					</div>
				</div>
			)}
			;
		</div>
	);
}

export default UsersList;
