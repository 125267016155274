import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import React, {
	useContext,
	useState,
	useReducer,
	useLayoutEffect,
	useEffect
} from 'react';
import { Store } from '../../Store';
import { LinkContainer } from 'react-router-bootstrap';
import './AdminNavbar.css';
import { useNavigate } from 'react-router-dom';
import AdminNavbarOptions from '../AdminNavbarOptions/AdminNavbarOptions';
import NavbarToggler from '../NavbarToggler/NavbarToggler';
import { RxCross1 } from 'react-icons/rx';
import _settings from '../../services/settings.service';
import AdminFooter from '../AdminFooter/AdminFooter';
import classnames from 'classnames';
import Avatar from 'react-avatar';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_COMPANY_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_COMPANY_SUCCESS':
		return {
			...state,
			imageURL:
					action.payload.image && action.payload.image.fileLink
						? action.payload.image.fileLink
						: 'https://gotasoft.io/images/logofooter.svg',
			companyName: action.payload.companyName,
			loading: false
		};
	case 'FETCH_COMPANY_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function AdminNavbar() {
	const [{ imageURL, companyName }, dispatch] = useReducer(reducer, {
		imageURL: '',
		companyName: '',
		loading: false,
		error: ''
	});

	const [isScrolled, setIsScrolled] = useState();
	const [currentTitle, setCurrentTitle] = useState('');

	const fetchData = async () => {
		try {
			dispatch({ type: 'FETCH_COMPANY_REQUEST' });
			const  data  = await _settings.getPublicSettings();
			dispatch({ type: 'FETCH_COMPANY_SUCCESS', payload: data });
		} catch (err) {
			console.error(err);
			dispatch({ type: 'FETCH_COMPANY_FAIL', payload: err.message });
		}
	};

	useLayoutEffect(() => {
		if (!userInfo || (userInfo && !userInfo.isAdmin)) {
			navigate('/signin');
		}
		fetchData();
		window.addEventListener('resize', () => {
			if (window.innerWidth > 992) {
				setActiveCollapse(true);
			}
		});
	}, []);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				const sectionTitle = document.querySelector('.section-title');
				setIsScrolled(true);
				setCurrentTitle(sectionTitle ? sectionTitle.textContent : '');
			} else {
				setIsScrolled(false);
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	const signoutHandler = () => {
		ctxDispatch({ type: 'USER_SIGNOUT' });
		navigate('/signin');
	};

	const [isActiveCollapse, setActiveCollapse] = useState(true);

	const handleToggleCollapse = () => {
		setActiveCollapse(!isActiveCollapse);
	};
	const Collapse = () => {
		setActiveCollapse(true);
	};
	

	return (
		<div>
			<ToastContainer position="bottom-center" limit={1}></ToastContainer>
			<header className="header-admin">
				<Navbar className="container-fluid admin-navbar" expand="d-none ">
					<NavbarToggler onClick={handleToggleCollapse} className="admin-toggler" />
					<Navbar className="navbar-collapse-Admin">
						{isActiveCollapse ? (
							<div className="d-flex h-100 w-100 flex-column flex-wrap justify-content-between">
								<AdminNavbarOptions
									companyName={companyName}
									imageURL={imageURL}
									handleCollapse={Collapse}
								/>
								<AdminFooter companyName={companyName} />
							</div>
						) : (
							<Modal
								backdropClassName="collapsable-navbar-backdrop"
								show={!isActiveCollapse}
								dialogClassName="collapsable-navbar-extended"
							>
								<Row className="justify-content-end mt-3 w-100">
									<button
										className="col-md-1 col-sm-2 col-3 exit-menu"
										onClick={Collapse}
									>
										<RxCross1 />
									</button>
								</Row>
								<AdminNavbarOptions handleCollapse={Collapse} />
							</Modal>
						)}
					</Navbar>
					<div className="admin-navbar-right">
						<div className={classnames('admin-nav-title', { show: isScrolled })}>
							{' '}
							{currentTitle}
						</div>
						{userInfo ? (
							<NavDropdown
								title={
									<Avatar
										fgColor=""
										size="35px"
										textSizeRatio={2}
										color="rgba(4,30,73,0.7)"
										name={userInfo.name}
									/>
								}
								drop="down"
								id="admin-nav-dropdown"
							>
								<LinkContainer to={'/AdminScreen/profile'}>
									<NavDropdown.Item>Mi Perfil</NavDropdown.Item>
								</LinkContainer>
								<NavDropdown.Divider />
								<li className="rightlinks">
									{' '}
									<Link className="dropdown-item" to="/signin" onClick={signoutHandler}>
										<i className="fa fa-sign-out " aria-hidden="true"></i>
										Salir
									</Link>
								</li>
							</NavDropdown>
						) : (
							<li className="rightlinks">
								{' '}
								<Link className="nav-link" to="/signin">
									<i className="fa fa-user" aria-hidden="true"></i>
									<span className="d-none d-lg-block">Ingresar</span>
								</Link>
							</li>
						)}
					</div>
				</Navbar>
			</header>
		</div>
	);
}

export default AdminNavbar;
