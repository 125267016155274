import LoadingBox from '../../components/LoadingBox';
import React, { useReducer, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
	Form,
	Button,
	Modal,
	Col,
	Container,
	Row,
	Card,
	Stack
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getError } from '../../utils';
import bookingTemplateService from '../../services/bookingTemplate.service';
import { useNavigate, useParams } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, bookingTemplate: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function EditBookingTemplate() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, bookingTemplate }, dispatch] = useReducer(reducer, {
		bookingTemplate: {},
		loading: true,
		error: ''
	});
	const [name, setName] = useState('');
	const [price, setPrice] = useState('');
	const [daysLast, setDaysLast] = useState('');
	const [totalBookings, setTotalBookings] = useState('');
	const [isActive, setIsActive] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showModalCascade, setShowModalCascade] = useState(false);
	const handleClose = () => {
		setShowModal(false);
	};
	const handleShow = () => setShowModal(true);
	const handleCloseCascade = () => {
		setShowModalCascade(false);
		setShowModal(false);
	};
	const handleShowCascade = () => setShowModalCascade(true);
	const handleIsActive = () => {
		setIsActive(!isActive);
	};

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await bookingTemplateService.getBookingTemplateById(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result });
				setName(result.name);
				setPrice(result.price);
				setDaysLast(result.daysLast);
				setTotalBookings(result.totalBookings);
				setIsActive(result.isActive);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchData();
	}, [id]);

	const editBookingTemplateHandler = async (cascade) => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const bookingTemplateData = {
				name: name,
				price: price,
				daysLast: daysLast,
				totalBookings: totalBookings,
				isActive: isActive,
				cascade: cascade
			};
			await bookingTemplateService.updateBookingTemplate(
				bookingTemplate._id,
				bookingTemplateData
			);
			dispatch({
				type: 'UPDATE_SUCCESS'
			});
			toast.success('BookingTemplate actualizado Correctamente');
			navigate('/AdminScreen/bookings');
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL' });
			toast.error(getError(err));
		}
	};

	return (
		<div>
			{loading && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Guardar Cambios</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea guardar los cambios?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancelar
					</Button>
					<Button onClick={handleShowCascade}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				size="lg"
				show={showModalCascade}
				onHide={handleCloseCascade}
				animation={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>Actualizar en cascada</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Actualizar en cascada los UserBookings relacionados?
					{totalBookings < bookingTemplate.totalBookings ||
					daysLast < bookingTemplate.daysLast
						? 'Disclaimer: En este caso totalBookings o daysLast son menores a sus valores anteriores. En cualquier caso no modificaran las reservas realizadas, si se desean reducri, debera hacerlas manualmente.'
						: ''}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={() => {
							editBookingTemplateHandler(false);
						}}
					>
						No
					</Button>
					<Button
						onClick={() => {
							editBookingTemplateHandler(true);
						}}
					>
						Si
					</Button>
				</Modal.Footer>
			</Modal>
			<Container className="large-container">
				<Helmet>
					<title>Editar BookingTemplate</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="border border-3 border-primary"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
											Editar BookingTemplate
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group className="mb-3" controlid="name">
													<Form.Label>Nombre:</Form.Label>
													<Form.Control
														type="text"
														required
														defaultValue={bookingTemplate.name}
														onChange={(e) => setName(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Group className="mb-3" controlid="precio">
													<Form.Label>Precio</Form.Label>
													<Form.Control
														type="text"
														required
														defaultValue={bookingTemplate.price}
														onChange={(e) => setPrice(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Group className="mb-3" controlid="duracion">
													<Form.Label>Duracion</Form.Label>
													<Form.Control
														type="text"
														required
														defaultValue={bookingTemplate.daysLast}
														onChange={(e) => setDaysLast(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Group className="mb-3" controlid="cupos">
													<Form.Label>Cupos</Form.Label>
													<Form.Control
														type="text"
														required
														defaultValue={bookingTemplate.totalBookings}
														onChange={(e) => setTotalBookings(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Group className="mb-3 col-3" controlId="isActive">
													<Form.Label>Esta activo?</Form.Label>
													<Form.Check
														type="checkbox"
														defaultChecked={bookingTemplate.isActive}
														onClick={handleIsActive}
														onTouchEnd={handleIsActive}
													></Form.Check>
												</Form.Group>

												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>
													<Button type="submit" className="mx-auto" onClick={handleShow}>
														Guardar Cambios
													</Button>
												</Stack>
												{/* <div className="mb-3">
                          
                       
                        </div> */}
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default EditBookingTemplate;
