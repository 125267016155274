import userService from '../../services/user.service';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import React, { useState, useReducer } from 'react';
import { toast } from 'react-toastify';
import { getError } from '../../utils';

export default function ResetPassword() {
	const reducer = (state, action) => {
		switch (action.type) {
		case 'RESET_REQUEST':
			return { ...state, loading: true };
		case 'RESET_SUCCESS':
			return { ...state, product: action.payload, loading: false };
		case 'RESET_FAIL':
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
		}
	};
	useReducer(reducer, {
		loading: true,
		error: ''
	});
	const navigate = useNavigate();
	const [email, setEmail] = useState('');

	const handleChange = (event) => {
		setEmail(event.target.value.toLowerCase());
	};

	async function resertPasswordHandler() {
		try {
			// dispatch({ type: "RESET_REQUEST" })
			await userService.forgotPassword(email);
			// dispatch({ type: "RESET_SUCCESS", payload: data });
			toast.success('Email Enviado');
			navigate('/');
		} catch (err) {
			toast.error(getError(err));
			// dispatch({ type: "RESET_FAIL" });
		}
	}

	return (
		<Container className="small-container">
			<Helmet>
				<title>Resetear Contraseña</title>
			</Helmet>
			<h1 className="my-3">Resetear Contraseña</h1>
			<Form onSubmit={(e) => e.preventDefault()}>
				<Form.Group className="mb-3" controlId="email">
					<Form.Label>E-mail</Form.Label>
					<Form.Control
						type="email"
						required
						onChange={handleChange}
						value={email}
					></Form.Control>
				</Form.Group>
				<div className="mb-3">
					<Button type="submit" onClick={resertPasswordHandler}>
						Resetear
					</Button>
				</div>
			</Form>
		</Container>
	);
}
