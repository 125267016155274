import LoadingBox from '../../components/LoadingBox';
import './FaqPage.css';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import * as React from 'react';
import faqService from '../../services/faq.service';
import utilsService from '../../services/utils.service';
import FaqQuestion from '../../components/FaqQuestion/FaqQuestion';

function FaqPage() {
	const [show, setShow] = useState(false);
	const [loadingClass, setLoadingClass] = useState('d-none');
	const [iframeClass, setIframeClass] = useState('d-none');
	const [iframeSrc, setIframeSrc] = useState('');
	const [questions, setQuestions] = useState([]);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	useEffect(() => {
		const fetchQuestions = async () => {
			try {
				const reqQuestions = await faqService.getAllQuestions();
				setQuestions(reqQuestions);
			} catch (ex) {
				console.error(ex);
			}
		};
		const fetchSrc = async () => {
			try {
				const iframeReq = await utilsService.getIframeUrl();
				setIframeSrc(iframeReq);
			} catch (ex) {
				console.error(ex);
			}
		};
		fetchQuestions();
		fetchSrc();
	}, []);
	useEffect(() => {
		if (show === true) {
			setLoadingClass('d-inline-block col-1');
			setIframeClass('d-none');
			setTimeout(() => {
				setLoadingClass('d-none');
				setIframeClass('d-block');
			}, 1000);
		}
	}, [show]);

	return (
		<div className="container container-faq mt-3">
			<div className="row">
				<h1 className="mb-3 col-10">¿En qué podemos ayudarte?</h1>
			</div>
			<div className="container-col-2 h-50">
				<Button
					variant="outline-danger"
					className="col-2 h-50"
					onClick={handleShow}
				>
					Reportar Error
				</Button>
			</div>
			<div>
				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body className="row justify-content-center align-items-center">
						<div className={loadingClass}>
							<LoadingBox></LoadingBox>
						</div>
						<iframe className={iframeClass} src={iframeSrc}></iframe>
					</Modal.Body>
				</Modal>
				{questions.length ? (
					<Accordion className="mt-1">
						{questions.map(({ question, answer }, index) => {
							return (
								<FaqQuestion
									key={index}
									question={question}
									answer={answer}
									eventKey={index}
								></FaqQuestion>
							);
						})}
					</Accordion>
				) : (
					<div className="row justify-content-center align-items-center">
						<LoadingBox></LoadingBox>
					</div>
				)}
				{/* <Accordion.Item eventKey="0" >
                        <Accordion.Header >
                            <h2>¿Qué horarios tienen?</h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>
                                De lunes a viernes: de 10:30. 14:30hs y de 17:00 a 20:30hs.<br />
                                Sábado: 10:00 a 14:00hs
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" >
                        <Accordion.Header >
                            <h2>¿Hacen envios? ¿Tiene costo?</h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Realizamos envíos en el día. <br />
                                Ir al apartado de envíos para consultar monto mínimo por zona
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" >
                        <Accordion.Header >
                            <h2>Medios de pago</h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Efectivo, débito y crédito, transferencia bancaria (con previo envío de comprobante de pago).
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" >
                        <Accordion.Header >
                            <h2>¿Con qué tarjetas trabajan?</h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Visa, Master, Oca, ticket de alimentos ( Sodexo, Edenred, itau), Cabal.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4" >
                        <Accordion.Header >
                            <h2>¿Dónde están ubicados?</h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Ramón Anador 3826 BIS esquina Andrés Aguiar. Barrio Buceo.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item> */}
			</div>
		</div>
	);
}
export default FaqPage;
