import analyticsService from '../../services/analytics.service';
import React, { useEffect, useReducer, useState, useRef } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { getError } from '../../utils';
import '../Screens.css';
import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	Line,
	LineChart
} from 'recharts';
import moment from 'moment/moment';
import { FaChartBar } from 'react-icons/fa';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, rawData: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function AdminScreen() {
	const [{ loading, error, rawData }, dispatch] = useReducer(reducer, {
		loading: true,
		error: '',
		rawData: null
	});

	const chartSection = useRef(null);
	const [barData, setBarData] = useState(null);
	const [certesianData, setCartesianData] = useState(null);
	const [certesianDataNames, setCartesianDataNames] = useState(null);
	const colors = [
		'#ea5545',
		'#f46a9b',
		'#ef9b20',
		'#edbf33',
		'#ede15b',
		'#bdcf32',
		'#87bc45',
		'#27aeef',
		'#b33dc6'
	];

	const months = [
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre'
	];

	const scrollDown = () => {
		chartSection.current.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const data = await analyticsService.getGeneralAnalytics();
				if (data && data.data) {
					top5(data.data);
					salesPerMonth(data.data);
				}
				dispatch({ type: 'FETCH_SUCCESS', payload: data.data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		fetchData();
	}, []);

	function top5(data) {
		const res = [];
		let i = 0;
		data.topSales.map((record) => {
			const sale = {
				name: record.template.name,
				Ventas: record.sales,
				color: colors[i]
			};
			res.push(sale);
			i++;
		});
		setBarData(res);
	}

	function salesPerMonth(data) {
		const res = [];
		const nombres = [];
		for (let index = 0; index < 12; index++) {
			const sale = {
				mes: months[index]
			};
			data.yearSales.map((record) => {
				if (index === 0) {
					nombres.push(record.template.name);
				}
				sale[`${record.template.name}`] = record.sales[index];
			});
			res.push(sale);
		}
		setCartesianDataNames(nombres);
		setCartesianData(res);
	}

	return (
		<div>
			{loading ? (
				<LoadingBox></LoadingBox>
			) : error ? (
				<MessageBox variant="danger">{error}</MessageBox>
			) : (
				<div>
					<div className="container admin-con">
						<div className="d-flex justify-content-center">
							<Row>
								<Row className="card d-flex flex-row">
									<h3 className="text-center">
										Indicadores de {`${months[moment().month()]}`}
									</h3>
									<Col className="m-2 col-lg-3 col-12 col-admin  list-group-item">
										<Row className="">
											<h5>Visitantes</h5>
											<div>Visitas del ultimo mes</div>
										</Row>
										<Row>
											<span>9998</span>
										</Row>
									</Col>
									<Col className="m-2 col-lg-3 col-12 col-admin  list-group-item">
										<Row className="">
											<h5>Total de ventas</h5>
											<div>Total de pedidos realizados el ultimo mes</div>
										</Row>
										<Row>
											<span>{rawData?.currentMonthIncome?.totalSales}</span>
										</Row>
									</Col>
									<Col className="m-2 col-lg-3 col-12 col-admin  list-group-item">
										<Row>
											<h5>Total de ingresos</h5>
											<div>Facturacion del mes corriente</div>
										</Row>
										<Row>
											<span>{rawData?.currentMonthIncome?.totalIncome}</span>
										</Row>
									</Col>
								</Row>
								<Row className="card d-inline-flex flex-row">
									<Card className="col-12 col-lg-4">
										<div className="row text-center">
											<h3 className="mt-3">Top Categorias</h3>
										</div>
										{barData ? (
											<Row>
												<ResponsiveContainer aspect={1}>
													<BarChart
														data={barData}
														margin={{
															top: 5,
															right: 30,
															left: 20,
															bottom: 5
														}}
													>
														<XAxis dataKey="name" />
														<Tooltip />
														<Bar dataKey="Ventas" fill="#8884d8">
															{barData.map((entry, index) => (
																<Cell fill={entry.color} key={`cell-${index}`} />
															))}
														</Bar>
													</BarChart>
												</ResponsiveContainer>
											</Row>
										) : null}
									</Card>
									<Card className="col-12 col-lg-4 align-middle justify-content-around text-center">
										<button className="btn btn-primary btn-lg" onClick={scrollDown}>
											<h1>
												<FaChartBar style={{ fontSize: '4rem' }}></FaChartBar>
											</h1>
											<h1>Ver Desempeño de ventas</h1>
										</button>
									</Card>
									<Card className="col-12 col-lg-4">
										<h3>Ultimos pedidos recibidos</h3>
										<Row>
											<Col>
												<div className="list-group ">
													{rawData.lastTen.map((sell) => (
														<a
															className="list-group-item mb-2 list-group-item-action"
															aria-current="true"
															key={sell._id}
														>
															<div className="d-flex w-100 justify-content-between">
																<h5 className="mb-1">¡Compra realizada!</h5>
																<small>{`${moment(sell.purchaseDate).format('DD/MM/yyyy HH:mm')}`}</small>
															</div>
															<p className="mb-1">
																{`${sell.user} compró la cuponera "${sell.item}" de ${sell.bookingsLeft} clases por $${sell.price}`}
															</p>
														</a>
													))}
												</div>
											</Col>
										</Row>
									</Card>
								</Row>
								<div className="row card d-flex flex-row">
									<div className="col-12 col-lg-12">
										<div className="row text-center">
											<h3>Ventas-Desempeño</h3>
										</div>
										<div className="row" ref={chartSection}>
											<ResponsiveContainer aspect={2}>
												<LineChart
													data={certesianData}
													margin={{
														top: 5,
														right: 30,
														left: 20,
														bottom: 5
													}}
												>
													<CartesianGrid strokeDasharray="3 3" />
													<XAxis dataKey="mes" />
													<YAxis />
													<Tooltip />
													<Legend />
													{certesianDataNames.map(function (nombre, i) {
														return (
															<Line
																type="monotone"
																key={`${nombre}`}
																dataKey={`${nombre}`}
																stroke={colors[i]}
																strokeWidth={4}
															/>
														);
													})}
												</LineChart>
											</ResponsiveContainer>
										</div>
									</div>
								</div>
							</Row>
						</div>
					</div>
				</div>
			)}
			;
		</div>
	);
}

export default AdminScreen;
