import restClient from '../utils/restClient';
import authHeader from './auth-header';
import { handleErrors } from './common.service';

const USER_BOOKING_API_URL = '/userbookings';

class UserBookingService {
	async getAllUserBookings() {
		try {
			const response = await restClient.get(USER_BOOKING_API_URL, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async createUserBooking(userBookingData) {
		try {
			const response = await restClient.post(
				USER_BOOKING_API_URL,
				userBookingData,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getUserBookingById(userBookingId) {
		try {
			const response = await restClient.get(
				`${USER_BOOKING_API_URL}/${userBookingId}`,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getUserBookingsByUserId(userId) {
		try {
			const response = await restClient.get(
				`${USER_BOOKING_API_URL}/user/${userId}`,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async deleteUserBooking(userBookingId) {
		try {
			const response = await restClient.delete(
				`${USER_BOOKING_API_URL}/${userBookingId}`,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async toggleUserBookingStatus(userBookingId) {
		try {
			const response = await restClient.put(
				`${USER_BOOKING_API_URL}/toggleactive/${userBookingId}`,
				{},
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async updateUserBooking(userBookingId, updatedData) {
		try {
			const response = await restClient.put(
				`${USER_BOOKING_API_URL}/${userBookingId}`,
				updatedData,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getUserBookingsLeft(userId) {
		try {
			const response = await restClient.get(
				`${USER_BOOKING_API_URL}/user/bookingsleft/${userId}`,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
}

export default new UserBookingService();
