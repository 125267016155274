import React from 'react';
import '../App.css';
import { useLayoutEffect, useReducer, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { BsWhatsapp, BsFacebook, BsInstagram } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import './Footer.css';
import settingsService from '../services/settings.service';

const Footerr = () => {
	const reducer = (state, action) => {
		switch (action.type) {
		case 'FETCH_COMPANY_REQUEST':
			return { ...state, loading: true };
		case 'FETCH_COMPANY_SUCCESS':
			return { ...state, GotaSettings: action.payload, loading: false };
		case 'FETCH_COMPANY_FAIL':
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
		}
	};
	const [{ loading, GotaSettings }, dispatch] = useReducer(reducer, {
		GotaSettings: {},
		loading: true,
		error: ''
	});

	const [imageURL, setImageURL] = useState();

	useLayoutEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_COMPANY_REQUEST' });
				const data = await settingsService.getPublicSettings();
				setImageURL(data.image.fileLink);
				dispatch({ type: 'FETCH_COMPANY_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_COMPANY_FAIL', payload: err.message });
			}
		};
		fetchData();
	}, []);

	const location = useLocation();
	const route = location.pathname;

	if (route.includes('AdminScreen')) return null;

	return !loading ? (
		<footer className="footer-bs">
			<div className="row">
				<div className="col-12 footerbrand-img col-lg-1">
					<img src={imageURL} alt="logo" />
				</div>
				<div className="col-12 footerbrand col-lg-3">
					<h5>{GotaSettings.companyName}</h5>
					<p className="footerbrand-sub">{GotaSettings.companysubName}</p>
					<p className="footerbrand-info">
						{GotaSettings.companyAddress}
						<br></br>
						{GotaSettings.companyCity}
						<br></br>Tel:
						{GotaSettings.companyPhone}
					</p>
				</div>
				<div className="col-lg-2 col-12">
					<div className="row">
						<p className="footer-sub">Nosotros</p>
						<ul>
							{/* <li>
                {" "}
                <Link className="nav-link" to="/contact">
                  Contacto
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="/shipping">
                  Envios
                </Link>
              </li> */}
							<li className="row d-none d-lg-block">
								<ul className="socialNavbarLinks-bottom-left social-links">
									<li>
										{' '}
										<a className="nav-link" href="#">
											<BsFacebook color="var(--IconsColor)" fontSize="1.5rem" />
										</a>
									</li>
									<li>
										{' '}
										<a className="nav-link" href="https://www.instagram.com/gotasoft/">
											<BsInstagram color="var(--IconsColor)" fontSize="1.5rem" />
										</a>
									</li>
									<li>
										<a className="nav-link" href="#">
											<BsWhatsapp color="var(--IconsColor)" fontSize="1.5rem" />
										</a>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>

				<div className="col-lg-2 col-12">
					<div className="row">
						<p className="footer-sub">Links Utiles</p>
						<ul>
							<li>
								{' '}
								<Link className="nav-link" to="/">
									Inicio
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
			{/* <a
        href="#"
        className="float_icon"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BsFillChatDotsFill color="white" />
      </a> */}
		</footer>
	) : null;
};

export default Footerr;
